import SectionHeader from './section-header'
import styles from './skills.module.scss';
import img from '../images/ic/32/arrow.svg';
import { useEffect, useState } from 'react';
import AOS from "aos";

function Skills(props) {

  const goToPage = (link) => {
    if (link) {
      window.open(link, "_blank")
    }
  }


  return (<>
    <SectionHeader title={props.title} subtitle={props.subtitle} />
    <div className={styles.skills}>
      <div className="container">
        {props.items.map(item => (<div data-aos="fade-up" className={`${styles.skills__skill} ${item.link ? styles.link : ''}`} onClick={(e) => { goToPage(item.link) }}><div className={styles.skills__skill__name}>
          {item.country &&
            <div className={styles.skills__skill__lang}>[{item.country}]</div>
          }
          {item.title}
          {item.link && <img src={img} />}
        </div>{item.text && <span>{item.text}</span>}</div>))}
      </div>
    </div>
  </>)
}
export default Skills
