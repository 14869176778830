import PortfolioChanger from './portfolio-changer'
import styles from './header.module.scss';
import logo from '../images/logo.svg';
import { Link } from 'react-router-dom';

function Header() {

  const scrollContact = () => {
    document.querySelector('#contact').scrollIntoView({behavior: 'smooth'})
  }

  return (
    <div className={styles.header}>
      <div className={styles.header__logo}>
        <Link to={'/'}>
          <img src={logo} />
        </Link>
      </div>
      <PortfolioChanger />
      <div className={styles.header__contact} onClick={() => scrollContact()}>
        Available for freelance
      </div>
    </div>
  )
}
export default Header
