import About from "../components/about";
import Portfolio from "../components/portfolio";
import Skills from "../components/skills";
import SocialMedia from "../components/social-media";
import Title from "../components/title";
import emoticon from '../images/emoticon.png';
function Designer() {

    const skills = [
        { title: "End-to-end product design" },
        { title: "Interface design" },
        { title: "User experience design" },
        { title: "Design systems thinking" },
        { title: "Business-driven & product strategy" },
        { title: "Concept development" }
    ]

    const about = "<h2>Simple, <br /> useful &amp; <br /> impactful <br /> experiences.</h2> <p> I’m Jordan — a product designer with 7+ years of experience. Currently living in Paris where I'm working at WeSave : a FinTech company, acquired by Amundi. </p> <p> Now, I collaborate hand in hand with both design and user experience team helping to redefine digital banking products by balancing business priorities with those of users who use our services. From the early stages of definition, to the product delivery. </p> <p> Before that journey, I worked in-house for an agency called VisualPanda. We were a small team but that’s the reason I’ve learnt a lot, passing all stages from Junior UI Designer to Product Designer. </p> <p> Having grown up with two cultures, I'm passionate about visiting other countries and discovering new environments. I love working with people and especially with those that share the same passion with me for what they do.</p>";

    return (
        <>
            <About text={about} image={emoticon}></About>
            <Portfolio></Portfolio>
            <Skills items={skills} title="Skills" subtitle="What can I do for you"></Skills>
        </>
    )
}

export default Designer;