
import img from '../images/ic/24/arrow.svg';
import styles from './portfolio-item.module.scss';
import AOS from "aos";
import { useEffect, useState } from 'react';

function PortfolioItem(props) {
  const { data } = props;
  return (<>
    {data.title &&
      <div className={styles.item}>
        <a href={data.html_url} target="_blank">
          <img src={data.images.hidpi} alt={data.title} className={styles.item__image} />
        </a>

        <div className={styles.item__title}><a href={data.html_url} target="_blank">{data.title} <img src={img} /></a></div>
      </div>
    }
    {!data.title && <div className={styles.item}>
      <div className={styles.placeholder__image}>
      </div>
      <div className={styles.item__title}>&nbsp;</div>
    </div>
    }
  </>)
}
export default PortfolioItem
