import Form from './components/form';
import Footer from './components/footer';
import Header from './components/header';
import { Route, Routes, useLocation, useRoutes } from 'react-router-dom';
import Designer from './pages/designer';
import Journalist from './pages/journalist';
import classNames from 'classnames';
import Title from './components/title';
import SocialMedia from './components/social-media';
function App() {

  const location = useLocation();

  const classes = classNames('app', {
    'inverted': location.pathname.includes('journalist'),
  })

  const getTitle = () => {
    if (location.pathname.includes('journalist')) {
      return "<span><span>👋 Hey, I’m Jordan. </span></span> <span><span>Freelance Sports Journalist.</span></span> <span><span>Based in Paris, France.</span></span>";
    } else {
      return "<span><span>👋 Hey, I’m Jordan. </span></span><span><span>Digital Product Designer who</span></span> <span><span>works in FinTech. Based in Paris,</span></span> <span><span>France.</span></span>";
    }
  }

  return (
    <div className={classes}>
      <Header />
      <Title title={getTitle()}></Title>
      <div className="container" data-aos="fade-up">
        <SocialMedia></SocialMedia>
      </div>
      <Routes>
        <Route path="/" element={<Designer />} />
        <Route path="journalist" element={<Journalist />} />
      </Routes>
      <Form></Form>
      <Footer></Footer>
    </div>
  );
}

export default App;
