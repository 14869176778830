import PortfolioItem from './portfolio-item'
import SectionHeader from './section-header'
import Button from './button';
import styles from './portfolio.module.scss';
import { useEffect, useState } from 'react';
import AOS from "aos";
import axios from "axios";

function Portfolio() {

  const [data, setData] = useState([{},{},{},{}]);
  useEffect(() => {
  
  });

  useEffect(() => {
    //243298757ee72cbec959e8e8f306abad9082f8bb1a531d20d02416f40a2e7df1
    axios.get('https://api.dribbble.com/v2/user/shots?access_token=243298757ee72cbec959e8e8f306abad9082f8bb1a531d20d02416f40a2e7df1').then(r => {
      console.log(r.data);
      setData(r.data.filter(r => !r.scheduled_for).slice(0, 4));
      AOS.refresh();
    })
  }, [])

  return (<div className={styles.portfolio}>
    <SectionHeader title="Work" subtitle="My latest projects" />
    
    {data.map((r,key) => <div data-aos="fade-up"><PortfolioItem key={key} data={r}/></div>)}

    <div className={styles.button} data-aos="fade-up">
      <Button link="https://dribbble.com/JordanBrnt" type="black">See more on dribbble</Button>
    </div>
  </div>)
}
export default Portfolio
