import style from './section-header.module.scss';
import AOS from "aos";
import { useEffect, useState } from 'react';

function SectionHeader(props) {
  useEffect(() => {
  
  });
  return (<>
    <div className="container" data-aos="fade-up">
      <div className={style.header}>
        <div className={style.header__title}>{props.title}</div>
        {props.subtitle &&
          <div className={style.header__link}>{props.subtitle} ↓</div>
        }
      </div>
    </div>
  </>)
}
export default SectionHeader
