import styles from './about.module.scss';
import Button from './button';
import { useEffect, useState } from 'react';
import AOS from "aos";

function About(props) {

  useEffect(() => {

  });
  return (<div className={styles.about}>
    <div className="container">
      <div className={styles.about__inner}>
        <div className={styles.about__text}>
          <div dangerouslySetInnerHTML={{__html: props.text}} data-aos="fade-up">

          </div>
          <div className={styles.about__button} data-aos="fade-up">
            <Button link="http://jordanberndt.com/JordanBerndtResume.pdf">See full Resume</Button>
          </div>
        </div>
        <div className={styles.about__image} data-aos="fade-up">
          <img src={props.image} />
        </div>
      </div>
    </div>
  </div>)
}
export default About
