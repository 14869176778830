import styles from './button.module.scss';
import classNames from 'classnames';
import {useState} from 'react';
function Button(props) {

  const [copied, setCopied] = useState(false);

  const copyEmail = () => {
    navigator.clipboard.writeText('work@jordanberndt.com')
    setCopied(true);

    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }

  const classes = classNames(styles.button, {
    [`${styles.black}`]: props.type == 'black',
    [`${styles.email}`]: props.type == 'email',
  })

  if (props.link == null) {
    return <div className="tooltip"><div onClick={() => { copyEmail() }} className={classes}>
      {props.children}
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.7403 0H2.92213C2.02213 0 1.28577 0.736364 1.28577 1.63636V13.0909H2.92213V1.63636H12.7403V0ZM15.1949 3.27273H6.19486C5.29486 3.27273 4.55849 4.00909 4.55849 4.90909V16.3636C4.55849 17.2636 5.29486 18 6.19486 18H15.1949C16.0949 18 16.8312 17.2636 16.8312 16.3636V4.90909C16.8312 4.00909 16.0949 3.27273 15.1949 3.27273ZM15.1949 16.3636H6.19486V4.90909H15.1949V16.3636Z" fill="white" />
      </svg>
    </div>
      {copied &&
        <div className="tooltip__body"><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="8.48529" width="12" height="12" transform="rotate(-45 0 8.48529)" fill="#7B61FF"/>
        </svg>
        
          Email copied to clipboard!
        </div>
      }
    </div>
  }

  return (props.link && <a href={props.link} target="_blank" className={classes}>{props.children}
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.66667 0V1.86667H10.8173L0 12.684L1.316 14L12.1333 3.18267V9.33333H14V0H4.66667Z" fill="white" />
    </svg>
  </a>)
}
export default Button
