import { NavLink } from 'react-router-dom';
import styles from './portfolio-changer.module.scss';

function PortfolioChanger() {
    return (<div className={styles.portfolioChanger}>
        <NavLink className={({ isActive }) => isActive ? styles.active : ""} to={'/'}>
            Product Designer
        </NavLink>
        <NavLink className={({ isActive }) => isActive ? styles.active : ""} to={'/journalist'} >
            Journalist</NavLink>
            <div class={styles.portfolioChanger__bar}></div>
    </div>)
}
export default PortfolioChanger
