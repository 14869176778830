import { useEffect, useState } from 'react';
import AOS from "aos";
import styles from './title.module.scss';
import classNames from 'classnames';

function Title(props) {

  const [active, setActive] = useState(false);
  const [animationEnded, setAnimationEnded] = useState(false);

  useEffect(() => {
    AOS.init();

    setActive(true);
    setTimeout(() => {
      setAnimationEnded(true);
    }, 550)
  }, [])

  const classes = classNames(styles.title, {
    [`${styles.title__active}`]: active,
    [`${styles.title__ended}`]: animationEnded,
  });


  return (<div className="container"><h1 className={classes} dangerouslySetInnerHTML={{ __html: props.title }}></h1> <p className={styles.date} data-aos="fade-up">◎︎ Last updated —  April 2022</p> </div>)
}
export default Title
