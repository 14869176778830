import twitterIcon from '../images/btn/40/black/twitter.svg';
import instaIcon from '../images/btn/40/black/instagram.svg';
import dribbleIcon from '../images/btn/40/black/dribbble.svg';
import linkedinIcon from './../images/btn/40/black/linkedin.svg';
import styles from './social-media.module.scss';
import classNames from 'classnames'; 
import { useEffect, useState } from 'react';

function SocialMedia(props) {


  const classes = classNames(styles.social, {
    [`${styles.no_margin}`]: props.type == 'footer'
  })
  return (
    <ul className={classes}>
      <li>
        <a href="https://twitter.com/JordanBerndt" taget="_blank">
          <img src={twitterIcon} />
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/jordanbrnt/" target="_blank">
          <img src={instaIcon} />
        </a>
      </li>
      <li>
        <a href="https://dribbble.com/JordanBrnt" target="_blank">
          <img src={dribbleIcon} />
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/in/jordan-berndt-921046145/" target="_blank">
          <img src={linkedinIcon} />
        </a>
      </li>
    </ul>)
}
export default SocialMedia
