import About from "../components/about";
import Skills from "../components/skills";
import SocialMedia from "../components/social-media";
import Title from "../components/title";
import emoticon from '../images/emoticon-journalist.png';

function Journalist() {

    const about = "<h2>Covering <br /> French Football <br /> events - since <br /> 2008</h2><p>I’m Jordan — a sports journalist with more than 10 years of experience. Currently living in Paris where I'm working as freelance for Polish newspapers and TV channels.</p>   <p>My adventure with media started around 2008. I’m a huge football fan and I just love to feel sports emotions at work. I usually write articles about French football news and events for Piłka Nożna newspaper. I also previously collaborated with French medias like Footballski and Vice Sport. Since 2021, I’m acting as foreign correspondent for Polish TV broadcaster Eleven Sports.</p>    <p>As a side project, I was running for few years a media called Le Ballon Mag with a group of passionate young redactors. We were editing a printed magazine dedicated for french football and tried to share our passion with others.</p>    <p>On a daily basis, you can follow my work also on Twitter, where I comment the actuality.</p>";
          
    const experiences = [
                       {title: "Piłka Nożna newspaper", text: "Since 2008"},
        {title: "Canal+ Sport (Poland)", text: "2015"},
        {title: "Le Ballon Mag", text: "Since 2017-2022"},
        { title: "Eleven Sports", text: "Since 2021"}, 
    ] 
  
    const  work = [ 
        {  
            link: "https://youtu.be/G9zOvJYtrhQ",
            country: "PL",
            title: "Canal+ Sport",
            text: "Interview for Handball studio format"
        },
        {
            link: "https://footballski.fr/author/jordan-berndt",
            country: "FR",
            title: "Footballski",
            text: "Articles list"
        },
        {
            link: "https://www.vice.com/fr/contributor/jordan-berndt-footballski",
            country: "FR",
            title: "Vice Sport",
            text: "Articles list"
        },
        {
            link: "https://issuu.com/vivelaligue1/docs/leballon3_",
            country: "PL",
            title: "LeBallon Mag 2017",
            text: "Web edition"
        },
        {
            link: "https://issuu.com/vivelaligue1/docs/leballon4",
            country: "PL",
            title: "LeBallon Mag 2018",
            text: "Web edition"
        },
        {
            link: "https://issuu.com/vivelaligue1/docs/leballon6",
            country: "PL",
            title: "LeBallon Mag 2019",
            text: "Printed edition"
        },
        {
            link: "https://issuu.com/vivelaligue1/docs/leballon7-hq",
            country: "PL",
            title: "LeBallon Mag 2020",
            text: "Printed edition"
        },
        {
            link: "https://issuu.com/vivelaligue1/docs/leballon8-web-hq",
            country: "PL",
            title: "LeBallon Mag 2021",
            text: "Printed edition"
        },
    ]

    return (
        <>
            <About text={about} image={emoticon}></About>
            <Skills items={experiences} title="Experiences"></Skills>
            <Skills items={work} title="Work" subtitle="What can I do for you"></Skills>
        </>
    )
}

export default Journalist;