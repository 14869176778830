import Button from './button';
import styles from './footer.module.scss';
import SocialMedia from './social-media';

function Footer() {
  const copyEmail = () => {
    alert(1);
  }

  const year = () => {
    return new Date().getFullYear();
  }

  return (
    <div className={styles.holder}>
      <div className="container">
        <div className={styles.footer}>
          <div className={styles.footer__title}>
            Don’t hesitate to drop me an email <br />
            or contact me via my social profiles ✌️
          </div>
          <div className={styles.footer__footer}>
            <div className={styles.footer__left}>
              <Button onClick={() => {copyEmail()}} type="email">work@jordanberndt.com</Button>
            </div>
            <div className={styles.social_media}>
              <SocialMedia type="footer" />
            </div>
          </div>
          <div className={styles.footer__copy}>
            <div className={styles.footer__copy__left}>
              Handmade with ♡ in Paris.
            </div>
            <div className={styles.footer__copy__right}>
              ©{year()} Jordan Berndt
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Footer
